import axios from "axios";
import React from "react";

const ExcelUploader = () => {

    const uploadFile = (e) => {
        e.preventDefault();
        console.log('uploadFile');
    }

    const getData = async() => {
        const response = await axios.post('https://fiscal.pages.dev/api/greetings');
        console.log(response);
    }

    return (
        <form onSubmit={uploadFile}>
            <input type="file" />
            <button type="submit">Submit</button>
        </form>
    );
  }

export default ExcelUploader;