import logo from './logo.svg';
import './App.css';
import ExcelUploader from './components/ExcelUploader';

function App() {
  return (
    <ExcelUploader></ExcelUploader>
  );
}

export default App;
